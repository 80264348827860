<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__row">
        <div class="footer__left">
          <a href="#" class="footer__about">About Us</a>
          <a href="#" class="footer__offer">Public offer</a>
        </div>
        <a href="#" class="footer__center">
          <svg-icon name="dog"> </svg-icon>
          <span>info@visitukraine.today</span>
        </a>
        <div class="footer__right">
          <a href="#">
            <svg-icon name="youtube"></svg-icon>
          </a>
          <a href="#">
            <svg-icon name="insta"></svg-icon>
          </a>
          <a href="#">
            <svg-icon name="facebook"></svg-icon>
          </a>
          <a href="#">
            <svg-icon name="telegram"></svg-icon>
          </a>
        </div>
      </div>
      <div class="footer__second-row">
        <a href="#" class="footer__left">
          <img
            src="@/assets/images/logo/Emblem_of_the_Visit_Ukraine.svg"
            alt="Visit Ukraine"
          />
        </a>
        <div class="footer__second-right">
          <svg-icon name="visa" class="footer__pay-svg"></svg-icon>
          <svg-icon name="mastercard" class="footer__pay-svg"></svg-icon>
        </div>
      </div>
    </div>
    <div class="footer__copyrights">
      <a href="#">© VisitUkraine 2020 | All rights reserved</a>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="sass" scoped>
a
  text-decoration: none
  background: none

.footer
  background-color: #231F20

a:hover
  color: var(--btn-color-blue-hover)

svg
  fill: #fff
  cursor: pointer
  height: 15px

svg:hover
  fill: var(--btn-color-blue-hover)

.footer__center:hover svg
  fill: var(--btn-color-blue-hover)

.footer__row,
.footer__second-row,
.footer__copyrights
  color: #fff
  font-size: 11px
  display: flex
  justify-content: space-between
  font-weight: normal
  padding: 46px 20px 32px

  @media screen and (max-width: 1199px)
    flex-direction: column
    align-items: center
    padding: 46px 20px 0px

.footer__second-row
  padding: 0 20px 36px

.footer__copyrights
  background: #000
  justify-content: center
  padding: 16px 20px 15px

  @media screen and (max-width: 1199px)
    font-size: 14px

.footer__left
  display: flex
  gap: 34px
  width: 231px
  align-items: center

  @media screen and (max-width: 1199px)
    flex-direction: column
    align-items: center
    padding-bottom: 28px

.footer__left img
  @media screen and (max-width: 1199px)
    display: none

.footer__center
  display: flex
  align-items: center

  @media screen and (max-width: 1199px)
    margin-bottom: 30px

.a svg:hover
  fill: var(--btn-color-blue-hover) !important

.footer__center:hover
  color: var(--btn-color-blue-hover)
  fill: var(--btn-color-blue-hover) !important

.footer__right
  display: flex
  gap: 45px
  align-items: center

.footer__second-right
  display: flex
  gap: 17px

.footer__pay-svg
  height: 27px
  width: 43px
</style>
