<template>
  <main class="main">
    <Header />
    <router-view />
    <Footer />
  </main>
</template>

<script>
import Header from '@/components/Layout/Header/Header.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      modal: false,
    };
  },
  mounted() {},
};
</script>
<style lang="sass" scoped>
.main
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: space-between
  background: #F8F8FF
</style>
