<template>
  <header class="header" @click="closeLocalModal">
    <div class="container">
      <div class="header__inner">
        <div class="header__left">
          <div class="header__logos">
            <a target="_blank" href="https://visitukraine.today/">
              <img
                src="@/assets/images/logo/Emblem_of_the_Visit_Ukraine.svg"
                alt="Visit Ukraine"
                class="header__visit-logo"
              />
            </a>
            <a
              target="_blank"
              href="https://www.tourism.gov.ua/"
              class="header__dart-logo"
            >
              <img
                src="@/assets/images/logo/Emblem_of_the_Dart.svg"
                class="header__dart-logo"
                alt="Дарт"
              />
            </a>
            <a target="_blank" href="https://moz.gov.ua/">
              <img
                src="@/assets/images/logo/Emblem_of_the_MOZUkraine.svg"
                alt=" МОЗ"
                class="header__moz-logo"
              />
            </a>
          </div>
        </div>
        <button
          class="header__right"
          :class="{ isOpenModal: !localModal }"
          @click.stop="openLocalModal"
        >
          <ul v-if="localModal" class="local-menu">
            <li
              v-for="item in languages"
              :key="item.id"
              class="header__lang"
              :class="{ selected: selectedLangTitle === item.title }"
              @click="selectLang(item.id)"
            >
              {{ item.title }}
            </li>
          </ul>
          <span v-if="!localModal">
            {{ selectedLangTitle }}
            <svg-icon name="lang-arrow" class="header__lang-arrow"></svg-icon>
          </span>
          <span v-else>
            <svg-icon
              name="lang-arrow-up"
              class="header__lang-arrow"
            ></svg-icon>
          </span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      localModal: false,
      selectedLangTitle: '',
      languages: {
        en: {
          id: 'en',
          title: 'EN',
        },
        ru: {
          id: 'ru',
          title: 'RU',
        },
        uk: {
          id: 'uk',
          title: 'UA',
        },
      },
      defaultLang: 'en',
    };
  },
  computed: {},
  mounted() {
    this.selectedLangTitle = this.getLanguageTitleById(
      localStorage.getItem('multi_lang') || 'en'
    );
  },
  methods: {
    getLanguageTitleById(id) {
      return typeof this.languages[id] !== 'undefined'
        ? this.languages[id].title
        : '';
    },
    openLocalModal() {
      this.localModal = !this.localModal;
    },

    closeLocalModal() {
      this.localModal = false;
    },
    selectLang(language) {
      this.selectedLangTitle = this.getLanguageTitleById(language);
      this.$i18n.locale = language;
      this.$store.commit('SET_LANG', language);

      localStorage.setItem('multi_lang', language);

      let currentRoute = this.$router.currentRoute;
      let currentRouteParams = currentRoute.params;

      if (language === this.defaultLang) {
        delete currentRouteParams.lang;
      } else {
        currentRouteParams.lang = language;
      }

      this.$router.push({
        name: currentRoute.name,
        params: currentRouteParams,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  padding: 28px 0 55px 0

  @media screen and (max-width: 1199px)
    padding: 28px 0 32px 0

  @media screen and (max-width: 640px)
    padding: 22px 0 8px

.header__inner
  display: flex
  align-items: center
  justify-content: space-between

  @media screen and (max-width: 640px)
    padding: 0 16px

.header__left
  display: flex

.header__logos
  display: flex
  align-items: center
  gap: 24px

  @media screen and (max-width: 640px)
    gap: 5px

.header__visit-logo
  width: 250px
  height: 48px

  @media screen and (max-width: 1199px)
    width: 170px

  @media screen and (max-width: 640px)
    width: 110px

.header__dart-logo
  align-self: center
  width: 140px
  height: 35px

  @media screen and (max-width: 1199px)
    width: 100px

  @media screen and (max-width: 640px)
    width: 60px
    height: 14px

.header__moz-logo
  width: 85px

  @media screen and (max-width: 640px)
    width: 40px

.header__right
  position: relative
  display: flex
  align-items: center
  position: relative
  font-size: 14px
  font-weight: bold
  padding: 0 30px 0 20px
  margin: 0 -10px 0 -20px

  @media screen and (max-width: 1199px)
    padding-right: 30px

.header__right:hover span
  color: #4B4B4B

.local-menu
  position: absolute
  padding: 0 2px
  top: -8px
  left: 0

.header__lang
  position: relative
  padding-bottom: 12px

  @media screen and (max-width: 1199px)
    padding-bottom: 5px

.header__lang:hover
  color: #1957C1

.header__lang-arrow
  position: absolute
  right: 10px
  width: 8px
  height: 5px
  top: 50%
  transform: translateY(-50%)

  @media screen and (max-width: 1199px)
    right: 10px


.header__lang:before
  position: absolute
  content: ''
  left: -19px
  height: 7px
  width: 7px
  background: #7C7C7C
  border-radius: 50%
  top: 15%

  @media screen and (max-width: 640px)
    left: -12px

.header__right.isOpenModal:after
  position: absolute
  content: ''
  left: 1px
  background: #006EFF
  height: 7px
  width: 7px
  border-radius: 50%

.header__lang.selected:before
  background: #006EFF

.header__lang.selected
  color: #006EFF

.header__lang:hover:before
  background: #1957C1
</style>
